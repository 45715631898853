#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.ui.segments{
    border: none;
    box-shadow: none;
}

.ui.floating.icon.warning.message, .ui.floating.icon.success.message, .ui.floating.icon.error.message{
    padding-right:35px;
}

.ui.positive.button{
    background-color:#015b53;
}